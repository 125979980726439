:root {
  --mx-navmenu-height: 72px;
  --mx-sidebar-width: 280px;
  --mx-sidebar-collapsed-width: 60px;
  --mx-footer-height: 32px;
  --mx-navmenu-item-padding: 18px;
  --mx-navmenu-item-border: 4px;
  --mx-page-lr-padding: 80px;
}

sl-button.navlink::part(base) {
  border: none;
}

.card-basic {
  min-width: 240px;
  max-width: 240px;
}
